import { Component } from '@angular/core';
import { ListData, ListFilter, Type, offerStatus, sortList } from './models/company-offers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { CompaniesService } from '../../companies/services/companies.service';
import { CompanyOffersService } from './services/company-offers.service';
import { ListingModel } from '../../companies/enum/company';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-company-offers',
  templateUrl: './company-offers.component.html',
  styles: ``
})
export class CompanyOffersComponent {
  dataLoading = false;
  showSearch = false;
  showFilter = false;

  pageIndex = 0;
  pageSize = 4;
  pageCount = 0;
  recordsData!: ListData | null;
  offersList: any = []
  count_not_ended!: number
  count_ended!: number

  filterModel: ListFilter = new ListFilter();

  sortItems = [
    { name: 'general.Recently added', sort: sortList.DESC, sortBy: '', value: sortList.DESC },
    { name: 'general.Oldest added', sort: sortList.ASC, sortBy: '', value: sortList.ASC },
    { name: 'offers.Most redeemed', sort: sortList.redeemed, sortBy: '', value: sortList.redeemed },
    { name: 'offers.Most claimed', sort: sortList.claimed, sortBy: '', value: sortList.claimed },
    { name: 'companies.Creation Date', sort: sortList.created_at, sortBy: '', value: sortList.created_at }
  ];

  types = [
    { name: this.translate.instant('offers.not_customized'), id: Type.not_customized },
    { name: this.translate.instant('offers.customized'), id: Type.customized }
  ]

  typesListAll: any[] = [
    { name: 'companyOffers.Valid offers', id: offerStatus.not_ended_yet },
    { name: "companyOffers.Expired offers", id: offerStatus.ended }
  ];
  typeValue!: any;

  currentSortSelection!: any;

  filterFormGroup: FormGroup = new FormGroup({});
  today = new Date();


  Permissions!: any;
  OffeerStatus!: any;

  // Offer Types
  offerTypesLoading = false;
  offerTypes: any = [];


  // Cities
  citesModal = { perPage: 200, page: 1, search: '' }
  CitesList: any = []
  citiesCount: number = 0;
  citiesLoading = false;
  // cityPageIndex = 1;
  // cityPageSize = 200;
  // numberOfCitiesFromEndBeforeFetchingMore = this.cityPageIndex / 2;
  citySubscriptions: Subscription[] = [];

  // Vendors 
  vendorModal = { perPage: 20, page: 1, search: '' }
  vendorsLoading = false;
  vendorsList: any = []
  vendorsCount: number = 0;
  vendorPageIndex = 1;
  vendorPageSize = 10;
  numberOfVendorsFromEndBeforeFetchingMore = this.vendorPageIndex / 2;


  // Branches of Vendor
  branchesOfVendorLoading = false;
  branchesOfVendor!: any;

  // Companies 
  companyModel = { perPage: 20, page: 1, search: '' }
  companiesLoading = false;
  companiesList: any = []
  companyCount: number = 0;
  companyPageIndex = 1;
  companyPageSize = 10;
  numberOfCompaniesFromEndBeforeFetchingMore = this.companyPageSize / 2;
  loadingExport: boolean = false;
  loadingPrint: boolean = false;



  // Departements 
  departmentModal: ListingModel = new ListingModel()
  departmentsLoading = false;
  departmentsList: any = []
  departmentsCount: number = 0;
  numberOfDepartmentsFromEndBeforeFetchingMore = this.departmentModal.page / 2;
  subscribtionsDepartment: Subscription[] = []


  constructor(private translate: TranslateService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private service: CompanyOffersService,
    private companyService: CompaniesService,
    public _authService: AuthenticationService) { }

  ngOnInit(): void {
    this.OffeerStatus = this.typesListAll[0].id;
    this.filterModel.not_ended_yet = true;
    this.currentSortSelection = this.sortItems[0].value;
    this.OnSelectSortType();
    this.getAll(true);
    this.buildFilterForm();
    this.getOfferTypes();

    if (this._authService.currentUserValue?.company_id) {
      this.OnGetDepartment()
    } else {
      this.getAllCitites();
      this.getAllVendors();
    }
  }


  buildFilterForm() {
    this.filterFormGroup = this.formBuilder.group({
      city: [],
      vendor: [],
      branch: [],
      company: [],
      offerType: [],
      type: [],
      department_id: []
    });
  }

  getAllCitites() {
    this.citiesLoading = true;
    this.citySubscriptions.forEach(element => {
      element.unsubscribe();
    });
    // this.citesModal.page = this.cityPageIndex;
    // this.citesModal.perPage = this.cityPageSize;
    this.citySubscriptions.push(this.companyService.GetCities(this.citesModal).subscribe({
      next: (res: any) => {
        this.citiesLoading = false;
        this.CitesList = this.CitesList.concat(res.data.list);
        this.citiesCount = res.data.total;
      },
      error: (error: any) => {
        this.citiesLoading = false;
      }
    }))
  }

  dropSearch(data: any) {
    this.CitesList = [];
    this.citesModal.search = data?.term || '';
    this.getAllCitites();
  }

  OnClear() {
    this.filterFormGroup.patchValue({ city: null })
    this.citesModal.search = ''
    this.CitesList = [];
    this.getAllCitites();
  }


  getAllVendors() {
    this.vendorsLoading = true;
    this.vendorModal.page = this.vendorPageIndex;
    this.vendorModal.perPage = this.vendorPageSize;
    this.vendorModal.search = ''

    this.service.getVendorListWithSearch(this.vendorModal).subscribe({
      next: (res: any) => {
        this.vendorsLoading = false;
        this.vendorsList = this.vendorsList.concat(res.data.list);
        this.vendorsCount = res.data.total;
      },
      error: (error: any) => {
        this.vendorsLoading = false;
      }
    })
  }

  onVendorsScroll({ end }: any) {
    if (this.vendorsLoading || (this.vendorsList.length <= this.vendorsCount)) {
      return;
    }
    if (end + this.numberOfVendorsFromEndBeforeFetchingMore >= this.vendorsList.length) {
      this.vendorPageIndex++;
      this.getAllVendors();
    }
  }

  onVendorsScrollToEnd() {
    if (this.vendorsList.length < this.vendorsCount) {
      this.vendorPageIndex++;
      this.getAllVendors();
    }
  }

  getAllBranches() {
    this.filterFormGroup.controls['branch'].reset();
    this.branchesOfVendorLoading = true;
    this.service.getBranchesOfVendor(this.filterFormGroup.controls['vendor'].value).subscribe((data: any) => {
      this.branchesOfVendor = data.data;
      this.branchesOfVendorLoading = false;
    })
  }

  // getAllCompanies() {
  //   this.companiesLoading = true;
  //   this.companyModel.page = this.vendorPageIndex;
  //   this.companyModel.perPage = this.vendorPageSize;
  //   this.companyModel.search = ''
  //   this.companyService.CompaniesSearch(this.companyModel).subscribe((data) => {
  //     this.companiesList = this.companiesList.concat(data.data.list);
  //     this.companyCount = data.data.total;
  //     this.companiesLoading = false;
  //   })
  // }

  // onCompaniesScroll({ end }: any) {
  //   if (this.companiesLoading || (this.companiesList.length <= this.companyCount)) {
  //     return;
  //   }
  //   if (end + this.numberOfCompaniesFromEndBeforeFetchingMore >= this.companiesList.length) {
  //     this.companyPageIndex++;
  //     this.getAllCompanies();
  //   }
  // }

  // onCompaniesScrollToEnd() {
  //   if (this.companiesList.length < this.companyCount) {
  //     this.companyPageIndex++;
  //     this.getAllCompanies();
  //   }
  // }

  getOfferTypes() {
    this.offerTypesLoading = true;
    this.service.getOfferTypes().subscribe((data: any) => {
      this.offerTypes = data.data;
      this.offerTypesLoading = false;
    })
  }

  OnSelectSortType() {
    if (this.currentSortSelection == sortList.DESC || this.currentSortSelection == sortList.ASC) {
      this.filterModel.sort = this.currentSortSelection;
      this.filterModel.sortBy = sortList.created_at;
    }
    else {
      this.filterModel.sort = sortList.DESC
      this.filterModel.sortBy = this.currentSortSelection;
    }
  }

  typeFilter(selected: any) {
    if (selected.value == offerStatus.not_ended_yet) {
      this.filterModel.not_ended_yet = true;
      this.filterModel.ended = false;
    } else {
      this.filterModel.ended = true;
      this.filterModel.not_ended_yet = false;
    }
    this.getAll(false);
  }

  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.search) {
        this.getAll(false);
      }
    }
  }

  resetSearch() {
    this.filterModel.search = '';
    this.OnSelectSortType();
    this.getAll(false);
  }


  makeFilter() {
    const controls = this.filterFormGroup.controls;
    if (controls['city'].value) {
      this.filterModel.city_id = controls['city'].value;
    } else {
      this.filterModel.city_id = '';
    }
    if (controls['vendor'].value) {
      this.filterModel.vendor_id = controls['vendor'].value;
    } else {
      this.filterModel.vendor_id = '';
    }
    if (controls['branch'].value) {
      this.filterModel.branch_id = controls['branch'].value;
    } else {
      this.filterModel.branch_id = '';
    }
    if (controls['company'].value) {
      this.filterModel.company_id = controls['company'].value;
    } else {
      this.filterModel.company_id = '';
    }
    if (controls['offerType'].value) {
      this.filterModel.offer_type_id = controls['offerType'].value;
    } else {
      this.filterModel.offer_type_id = '';
    }
    // if (controls['type'].value) {
    //   this.filterModel.type = controls['type'].value;
    // } else {
    //   this.filterModel.type = '';
    // }
    if (controls['department_id'].value) {
      this.filterModel.department_id = controls['department_id'].value;
    } else {
      this.filterModel.department_id = '';
    }
    this.getAll(false);
  }

  clearFilter() {
    // this.filterModel = new ListFilter();
    // this.filterModel.sort = sortList.DESC;
    this.filterModel.city_id = '';
    this.filterModel.vendor_id = '';
    this.filterModel.branch_id = '';
    this.filterModel.company_id = '';
    this.filterModel.offer_type_id = '';
    // this.filterModel.type = '';
    this.filterModel.department_id = '';
    this.filterFormGroup.reset();
    this.filterModel.perPage = this.pageSize;
    this.OnSelectSortType();
    this.getAll(false);
  }

  getAll(event: any) {
    this.dataLoading = true;
    if (!event) {
      this.pageIndex = 0;
      this.pageSize = this.pageSize;
      this.offersList = [];
    }
    if (event && event.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }
    this.filterModel.page = this.pageIndex + 1;
    this.filterModel.perPage = this.pageSize;

    this.service.getAllOffers(this.filterModel).subscribe((res: ListData) => {
      this.dataLoading = false;
      if (res) {
        // this.recordsData = res;
        this.offersList = this.offersList.concat(res.data.list)
        // this.recordsData.data.list = this.recordsData.data.list.concat(res.data.list);
        this.pageCount = res.data.total;
        this.Permissions = res.permissions;
        this.count_not_ended = res.data.count_not_ended;
        this.count_ended = res.data.count_ended;
        // this.pageCount = Math.ceil(this.recordsData.data.total / this.filterModel.perPage);
      }
    },
      (error: any) => {
        // this.recordsData = null;
        this.dataLoading = false;
      });
  }

  loadMoreOffers() {
    this.pageIndex++;
    this.getAll(true);
  }

  counter(i: number) {
    return new Array(i);
  }


  OnExportExcel() {
    this.loadingExport = true;
    let model = this.filterModel
    this.service.ExportOffers(model).subscribe({
      next: (res: any) => {
        this.loadingExport = false;
        this.service.saveAsExcelFile(res, "Offers List.xlsx")
      },
      error: (error: any) => {
        this.loadingExport = false;
      }
    })
  }


  OnPrintPDF() {
    this.loadingPrint = true;
    let model = this.filterModel
    this.service.PrintPDF_Offers(model).subscribe({
      next: (res: any) => {
        this.loadingPrint = false;
        this.service.saveAsExcelFile(res, "Offers List.pdf")
      },
      error: (error: any) => {
        this.loadingPrint = false;
      }
    })

  }

  OnGetDepartment() {
    this.departmentsLoading = true;
    // this.departmentsList = []
    // this.CompaniesListModel.status = ''
    this.subscribtionsDepartment.forEach(element => {
      element.unsubscribe();
    });
    let model: any = { ... this.departmentModal }
    model.page += 1;
    model.company_id = this._authService.currentUserValue?.company_id
    this.subscribtionsDepartment.push(this.service.getDepartments(model).subscribe({
      next: (res: any) => {
        this.departmentsLoading = false;
        this.departmentsList = this.departmentsList.concat(res.data.list);
        this.departmentsCount = res.data.total;
      },
      error: (error: any) => {
        this.departmentsLoading = false;
      }
    }))
  }


  // ng-select department

  Department_dropSearch(data: any) {
    this.departmentModal.page = 0;
    this.departmentsList = [];
    this.departmentModal.search = data?.term || '';
    this.OnGetDepartment();
  }


  // department with Scroll
  onDepartmentsScroll({ end }: any) {
    if (this.departmentsLoading || (this.departmentsList.length <= this.departmentsCount)) {
      return;
    }
    if (end + this.numberOfDepartmentsFromEndBeforeFetchingMore >= this.departmentsList.length) {
      this.departmentModal.page++;
      this.OnGetDepartment();
    }
  }

  onDepartmentsScrollToEnd() {
    if (this.departmentsList.length < this.departmentsCount) {
      this.departmentModal.page++;
      this.OnGetDepartment();
    }
  }



}
